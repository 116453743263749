$color_grey: #787878;
$color_grey_light: #c3c3c3;
$color_white: #fff;
$color_black: #232323;

$aside_bg: #050810;
$aside_color: #d6d6d6;


$main_bg: #d6d6d6;
$main_color: #373833;

$title-margin: 2rem;
$title_size: 2rem;
$title_color: #3a6ca8;

$subtitle-margin: 0.3rem;
$subtitle_size: 1.6rem;
$subtitle_color: #373833;

$section-margin: 4rem;
$subsection-margin: 2.4rem;

$font-size: 1.4rem;
$line-height: 2.4rem;