@import 'variables';
body,
html {
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
}

body {
    font-size: $font-size;
    line-height: $line-height;
    font-family: Montserrat, serif;
}

* {
    box-sizing: border-box;
}

ul {
    margin: 0;
    padding: 0;
    li {
        margin: 0;
        padding: 0;
    }
}

.mat-drawer-inner-container {
    overflow-x: hidden !important;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #F7F7F7;
}

::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 0.4rem;
}

::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
}

::-moz-selection {
    color: #000;
    background: #40E998;
}

 ::selection {
    color: #000;
    background: #40E998;
}

@media print {
    @page {
        size: A4;
        margin: 0;
    }
    html {
        font-size: 85%;
    }
    html,
    body {
        width: 210mm;
        height: 297mm;
        background: #fff;
        overflow: visible;
    }
    body {
        padding: 0;
    }
}
